export default {
    items: [],
    display: 'value',
    current: null,
    parent: null,
    maxHeight: 0,
    sort: true,
    order: 'desc',
    placeholder: 'Select items',
    more: '(+{X} more)'
};
